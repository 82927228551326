import styled, { css, keyframes } from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

import { StyledPaginationItemProps } from "./types";

const progressBar = keyframes`
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
`;

export const CarouselNavigation = styled.div`
  display: flex;
  align-items: center;
`;

export const Pagination = styled.div`
  display: flex;
  width: calc(100% - 4rem);
  margin: 0 0.25rem;

  @media ${media.mobile} {
    margin: 0 2.125rem;
  }
`;

export const Number = styled.button`
  border: 0;
  background-color: transparent;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  opacity: 0.4;
  transition: ${({ theme }) => theme.transition};
  transition-property: opacity;
  padding: 0 0.375rem;

  @media ${media.tablet} {
    &:hover {
      opacity: 1;
    }
  }
`;

export const ProgressLine = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondaryDecor};
`;

export const ProgressStatus = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.accent};
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 1s linear;
`;

export const Progress = styled.span`
  position: relative;
  display: block;
  height: 1px;
  width: 0;
  margin-left: 0.5rem;
  transform: scaleX(0);
  transform-origin: left;
  transition: 0.3s ease-in-out;
  transition-property: transform, width, margin;

  @media ${media.mobile} {
    margin-left: 0.875rem;
  }
`;

export const PaginationItem = styled.div<StyledPaginationItemProps>`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5rem;

    @media ${media.mobile} {
      margin-left: 1.375rem;
    }
  }

  ${({ isSelected, slideChangeTime }) =>
    isSelected &&
    css`
      ${Number} {
        opacity: 1;
      }
      ${Progress} {
        transform: scaleX(1);
        width: 1.75rem;

        @media ${media.mobile} {
          width: 3.5rem;
        }
      }
      ${ProgressStatus} {
        animation: ${progressBar} ${slideChangeTime}ms linear;
      }
    `}
`;

export const Button = styled.button`
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.625rem 0.375rem;
  color: inherit;
  transition: ${({ theme }) => theme.transition};
  transition-property: color, opacity;
  flex-shrink: 0;

  svg {
    height: 1em;
    width: 1.3em;
    font-size: 0.9375rem;
    fill: currentColor;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  @media ${media.mobile} {
    padding: 0;
  }

  @media ${media.tablet} {
    &:hover {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;
