import { useState, useEffect, useCallback, useRef } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

import { SLIDE_CHANGE_TIME } from "./consts";

export const useCaseStudyCardCarousel = (itemsLength: number) => {
  const isSlider = itemsLength > 1;

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const autoplay = useRef(
    Autoplay({ delay: SLIDE_CHANGE_TIME, stopOnInteraction: false })
  );

  const [viewportRef, embla] = useEmblaCarousel(
    {
      loop: true,
      slidesToScroll: 1,
      containScroll: "trimSnaps",
      align: "start",
      active: isSlider,
      skipSnaps: false
    },
    [autoplay.current]
  );

  const [paginationViewportRef, emblaPagination] = useEmblaCarousel({
    active: true,
    containScroll: "keepSnaps",
    dragFree: true
  });

  const scrollPrev = useCallback(() => {
    if (!embla) return;

    embla.scrollPrev();

    if (autoplay && autoplay.current) {
      autoplay.current.reset();
    }
  }, [embla, autoplay]);

  const scrollNext = useCallback(() => {
    if (!embla) return;

    embla.scrollNext();

    if (autoplay && autoplay.current) {
      autoplay.current.reset();
    }
  }, [embla, autoplay]);

  const onPaginationClick = useCallback(
    (index: number) => {
      if (!embla) return;

      embla.scrollTo(index);

      if (autoplay && autoplay.current) {
        autoplay.current.reset();
      }
    },
    [embla, autoplay]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;

    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnDisabled(!embla.canScrollPrev());
    setNextBtnDisabled(!embla.canScrollNext());
    emblaPagination?.scrollTo(embla.selectedScrollSnap());
  }, [embla, emblaPagination, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;

    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  return {
    paginationViewportRef,
    selectedIndex,
    prevBtnDisabled,
    nextBtnDisabled,
    viewportRef,
    autoplay,
    scrollPrev,
    scrollNext,
    onPaginationClick,
    onSelect
  };
};
