import {
  StoryblokComponent,
  storyblokEditable
} from "@storyblok/react";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import CaseStudyCardCarousel from "@components/organisms/CaseStudyCardCarousel";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";
import { getImage } from "@utils/getImage";

import { CaseStudyCardCarouselStoryblokProps } from "./types";

const CaseStudyCardCarouselStoryblok: React.FC<
  CaseStudyCardCarouselStoryblokProps
> = ({ blok }) => {
  const intl = useIntl();
  const theme = useTheme();

  const { items, descriptionBackgroundColor } = blok;

  if (!items?.length) return null;

  const caseStudyDescriptionBckgroundColor =
    descriptionBackgroundColor?.color
      ? descriptionBackgroundColor.color
      : theme.colors.secondaryBackground;

  const carouselItems = items?.map(
    ({
      uuid,
      content: {
        title,
        description,
        techStackIcons,
        image,
        info,
        imageBackgroundColor
      },
      full_slug: fullSlug
    }) => ({
      key: uuid,
      buttonProps: {
        href: fullSlug,
        label: intl.formatMessage({
          defaultMessage: "Read case study"
        })
      },
      info: info?.length ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {info?.map((item) => {
            const { _uid: infoItemKey } = item;

            return (
              <FlexBox flex={1} key={infoItemKey}>
                <StoryblokComponent blok={item} />
              </FlexBox>
            );
          })}
        </>
      ) : undefined,
      imageProps: getImage(image),
      backgroundColor: imageBackgroundColor?.color,
      descriptionBackgroundColor: caseStudyDescriptionBckgroundColor,
      title,
      description,
      techStackList:
        techStackIcons?.map(({ filename }: { filename: string }) =>
          tryToReplaceDomain(filename)
        ) || []
    })
  );

  return (
    <CaseStudyCardCarousel
      items={carouselItems}
      {...storyblokEditable(blok)}
    />
  );
};

export default CaseStudyCardCarouselStoryblok;
