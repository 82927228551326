import Navigation from "@components/molecules/CarouselNavigation";
import CaseStudyCard from "@components/organisms/CaseStudyCard";

import { CaseStudyCardCarouselProps } from "./types";
import * as Styled from "./styles";
import { useCaseStudyCardCarousel } from "./hooks";
import { SLIDE_CHANGE_TIME } from "./consts";

const CaseStudyCardCarousel: React.FC<CaseStudyCardCarouselProps> = ({
  items
}) => {
  const {
    paginationViewportRef,
    selectedIndex,
    prevBtnDisabled,
    nextBtnDisabled,
    viewportRef,
    scrollPrev,
    scrollNext,
    onPaginationClick
  } = useCaseStudyCardCarousel(items.length);

  return (
    <Styled.Wrapper
      aria-label="Case study card carousel"
      ref={viewportRef}
    >
      <Styled.Content>
        {items.map(({ key, ...item }) => (
          <Styled.Slide {...{ key }}>
            <CaseStudyCard {...{ ...item }} isExternalUrl />
          </Styled.Slide>
        ))}
      </Styled.Content>
      {items.length > 1 ? (
        <Styled.Action>
          <Navigation
            ref={paginationViewportRef}
            itemsAmount={items.length}
            slideChangeTime={SLIDE_CHANGE_TIME}
            {...{
              onPaginationClick,
              selectedIndex,
              scrollPrev,
              scrollNext,
              prevBtnDisabled,
              nextBtnDisabled
            }}
          />
        </Styled.Action>
      ) : null}
    </Styled.Wrapper>
  );
};

export default CaseStudyCardCarousel;
