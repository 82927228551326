import {
  Box,
  FlexBox
} from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import { FormattedMessage } from "react-intl";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import { theme } from "@pagepro-monorepo/ui";

import Image from "@components/atoms/Image";
import LazyHydrate from "@components/utils/LazyHydrate";

import { CaseStudyCardProps } from "./types";
import * as Styled from "./styles";

const CaseStudyCard: React.FC<CaseStudyCardProps> = ({
  title,
  description,
  imageProps,
  buttonProps,
  descriptionBackgroundColor,
  backgroundColor,
  techStackList,
  info,
  isExternalUrl
}) => {
  const { label, href } = buttonProps;

  const { src = "", alt = "" } = imageProps || {
    src: "",
    alt: ""
  };

  return (
    <Styled.Wrapper
      flexDirection="column"
      aria-label="Case Study Card"
    >
      {imageProps && (
        <Styled.Column
          p={["1rem", "2rem", "3.25rem"]}
          {...{ backgroundColor }}
        >
          <FlexBox
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            height="100%"
          >
            <Styled.Media>
              <Image
                {...{ src, alt }}
                style={{
                  objectFit: "contain",
                  objectPosition: "center"
                }}
                fill
                // on mobile and tablet we want to display image in full width, on desktop we want to display it in half of the width
                sizes={`(max-width: ${theme.breakpoints[1]}px) 100vw, 50vw`}
              />
            </Styled.Media>
          </FlexBox>
        </Styled.Column>
      )}
      <Styled.Column
        p={[
          "1.25rem",
          "2.75rem 1.75rem 1.25rem",
          "3.75rem 6.875rem 3rem"
        ]}
        backgroundColor={descriptionBackgroundColor}
      >
        <FlexBox
          flexDirection="column"
          justifyContent="space-between"
          flexGrow={1}
          height="100%"
        >
          <div>
            <FlexBox
              flexDirection="column"
              mb={["2.625rem", "2rem", "2rem"]}
              minHeight={["", "8rem", "9.375rem"]}
              maxWidth={["", "32.5rem", "100%"]}
            >
              <Typography
                as="p"
                mb={["1rem", "0.75rem", "2.1875rem"]}
                variant="body8"
                fontWeight="medium"
              >
                {title}
              </Typography>
              <Typography as="h3" variant="body9" fontWeight={600}>
                {description}
              </Typography>
            </FlexBox>
            {info && (
              <FlexBox mb="3.875rem" display={["none", "", "flex"]}>
                {info}
              </FlexBox>
            )}
          </div>
          <div>
            <FlexBox
              justifyContent={["", "space-between", "flex-start"]}
              alignItems="center"
              mb={[0, 0, "3rem"]}
              px={[0, 0, "0.625rem"]}
              width="100%"
            >
              <Styled.ArrowRightIcon />
              <LazyHydrate whenVisible>
                <FlexBox
                  aria-label="Technology icons"
                  role="list"
                  as="ul"
                  display={["none", "flex", ""]}
                  flexWrap="wrap"
                >
                  {techStackList.map((techStackItem) => (
                    <Box
                      as="li"
                      role="listitem"
                      key={techStackItem}
                      mr={["", "", "XS"]}
                      ml={["XS", "", 0]}
                    >
                      <Styled.Icon src={techStackItem} />
                    </Box>
                  ))}
                </FlexBox>
              </LazyHydrate>
            </FlexBox>
            <Styled.Action>
              <Styled.Button
                aria-label="open case study page"
                target={isExternalUrl ? "_blank" : undefined}
                href={isExternalUrl ? href : `/${href}`}
                size="wide"
              >
                {label || (
                  <FormattedMessage defaultMessage="Discover" />
                )}
              </Styled.Button>
            </Styled.Action>
          </div>
        </FlexBox>
      </Styled.Column>
    </Styled.Wrapper>
  );
};

export default CaseStudyCard;
