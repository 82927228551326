import React from "react";

import IconArrowLeft from "@assets/svg/arrow-left.svg";
import IconArrowRight from "@assets/svg/arrow-right.svg";

import { CarouselNavigationProps } from "./types";
import * as Styled from "./styles";

const CarouselNavigation = React.forwardRef<
  HTMLDivElement,
  CarouselNavigationProps
>(
  (
    {
      itemsAmount,
      onPaginationClick,
      scrollPrev,
      scrollNext,
      prevBtnDisabled,
      nextBtnDisabled,
      selectedIndex,
      slideChangeTime
    },
    ref
  ) => (
    <Styled.CarouselNavigation ref={ref}>
      <Styled.Button
        type="button"
        onClick={scrollPrev}
        disabled={prevBtnDisabled}
        aria-label="Go to previous slide"
      >
        <IconArrowLeft />
      </Styled.Button>
      <Styled.Pagination aria-label="pagination">
        {Array.from(
          { length: itemsAmount },
          (item, index) => index
        ).map((item, index) => (
          <Styled.PaginationItem
            key={`pagination-${item}`}
            isSelected={index === selectedIndex}
            {...{ slideChangeTime }}
          >
            <Styled.Number
              onClick={
                onPaginationClick
                  ? () => onPaginationClick(index)
                  : undefined
              }
            >
              {index + 1 < 10 ? `0${index + 1}` : index + 1}
            </Styled.Number>
            <Styled.Progress>
              <Styled.ProgressLine />
              <Styled.ProgressStatus />
            </Styled.Progress>
          </Styled.PaginationItem>
        ))}
      </Styled.Pagination>
      <Styled.Button
        type="button"
        onClick={scrollNext}
        disabled={nextBtnDisabled}
        aria-label="Go to next slide"
      >
        <IconArrowRight />
      </Styled.Button>
    </Styled.CarouselNavigation>
  )
);

export default CarouselNavigation;
