import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

export const Wrapper = styled.div`
  width: 100%;

  overflow: hidden;

  &.is-draggable {
    cursor: move;
    cursor: grab;
  }

  &.is-dragging {
    cursor: grabbing;
  }
`;

export const Content = styled.ul`
  display: flex;
  gap: 1.875rem;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

export const Slide = styled.li`
  display: flex;
  min-width: 100%;
`;

export const Action = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2.75rem;

  @media ${media.mobile} {
    margin-top: 4.125rem;
  }
`;
