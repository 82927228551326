import styled from "styled-components";
import { ReactSVG } from "react-svg";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import {
  FlexBox,
  Box
} from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import shouldForwardProp from "@pagepro-monorepo/ui/lib/utils/shouldForwardProp";

import ButtonLink from "@components/atoms/ButtonLink";
import IconArrowRight from "@assets/svg/arrow-right.svg";

export const Wrapper = styled(FlexBox)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  @media ${media.tablet} {
    flex-direction: row-reverse;
  }
`;

export const Button = styled(ButtonLink)`
  position: initial;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Column = styled(Box).withConfig({
  shouldForwardProp
})`
  flex-basis: 50%;
`;

export const ArrowRightIcon = styled(IconArrowRight)`
  width: 1em;
  height: 1em;
  font-size: 1rem;

  @media ${media.mobile} {
    font-size: 1.25rem;
  }

  @media ${media.tablet} {
    display: none;
  }
`;

export const Media = styled.figure`
  width: 100%;
  padding-top: 75%;
  position: relative;
`;

export const Action = styled.div`
  @media ${media.maxTablet} {
    position: absolute;
    inset: 0;
    opacity: 0;
  }
`;

export const Icon = styled(ReactSVG)`
  svg {
    display: block;
    width: auto;
    height: 1em;
    font-size: 2rem;
    fill: currentColor;
  }
`;
